import React from 'react';
import ExpertiseSection from './ExpertiseSection';
import RecordSection from '../Home/RecordSection';
import { Container, Row } from 'react-bootstrap';
import OurPartnerSection from '../Home/OurPartnerSection';
import { ContentColumn, Description, Divider, Heading, Section } from '../IndustryPage';
import { aboutus_data , whyTechCloudERPContent} from '../../utils/constants';
// import TransformingIndustriesSection from '../../components/TransformingIndustriesSection';
import CTA from '../CTA'
import WhyTechCloudERP from './WhyTechCloudERP';
import Services from './Services';
import VisionMissionSection from './VisionMissionSection';
import CoFoundersSection from './CoFoundersSection ';
const Aboutus = () => {
  return (
    <>
      <Section>
        <Container>
          <Row>
            <ContentColumn md={6}>
              {/* Correct the reference to access about_content */}
              <Heading>{aboutus_data.about_content.heading}</Heading>
              <Divider />
              <Description>{aboutus_data.about_content.description}</Description>
            </ContentColumn>
          </Row>
        </Container>
      </Section>
<VisionMissionSection />      
      <ExpertiseSection />
      <WhyTechCloudERP content={whyTechCloudERPContent}   title={whyTechCloudERPContent.title}
      />
      <RecordSection />
      {/* <TransformingIndustriesSection /> */}
      <Services />
      <CoFoundersSection />
      <CTA />
      <OurPartnerSection />
    </>
  );
}

export default Aboutus;
