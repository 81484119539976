import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { ContentColumn, Description, Divider, Heading, Section } from '../../pages/IndustryPage';
import { aboutus_data } from '../../utils/constants';
import styled from 'styled-components';
import CTA from '../CTA'
import { modulesData } from '../../utils/constants';
import { FaArrowRight } from 'react-icons/fa'; // Import FaArrowRight from react-icons
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

// Styled Components
const StyledContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const StyledCard = styled(Card)`
  margin: 15px;
  border: none;
  box-shadow: rgba(173, 216, 230, 0.5) 0px 10px 20px, rgba(173, 216, 230, 0.7) 0px 6px 6px;
  height: 420px; /* Uniform card height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledCardBody = styled(Card.Body)`
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start; /* Align content uniformly */
`;

const StyledImage = styled(Card.Img)`
  height: 200px;
  width: 100%;
  object-fit: cover;
`;

const StyledExploreLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff5733;
  text-align: right;
  gap: 10px;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.3rem;
  margin-top: auto; /* Pushes button to bottom */
  margin-bottom: 14px;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-2px); /* Adds hover effect */
    color: #e04030;
  }
`;

const AllProductsSection = () => {
  return (
    <>
      <Section>
        <Container>
          <Row>
            <ContentColumn md={6}>
              <Heading>{aboutus_data.allproducts.heading}</Heading>
              <Divider />
              <Description>{aboutus_data.allproducts.description}</Description>
            </ContentColumn>
          </Row>
        </Container>
      </Section>
      <StyledContainer>
        <Row>
          {modulesData.map((module, index) => (
            <Col key={index} sm={12} md={6} lg={4}>
              <StyledCard>
                <StyledImage variant="top" src={module.image} alt={module.title} />
                <StyledCardBody>
                  <Card.Title>{module.title}</Card.Title>
                  <Card.Text>{module.description}</Card.Text>
                  <StyledExploreLink to={module.link}>
                    {module.linkText} <FaArrowRight className="explore-arrow" />
                  </StyledExploreLink>
                </StyledCardBody>
              </StyledCard>
            </Col>
          ))}
        </Row>
      </StyledContainer>
      <CTA />
    </>
  );
};

export default AllProductsSection;
